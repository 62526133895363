.gradient {
  background: linear-gradient(
    to right,
    var(--background-color, #1e1e1e) 0%,
    var(--background-color, #1e1e1e) 20%,
    rgb(0 0 0 / 0%) 100%
  );
  width: 50px;
  height: 100%;
  position: absolute;
  z-index: 3;
}

.withIndicatorArrow::before {
  content: '';
  background: #fff;
  width: 30px;
  height: 30px;
  position: absolute;
  top: calc(-15px - 1em);
  left: calc(50% - 15px);

  --tw-rotate: 45deg;

  transform: rotate(var(--tw-rotate));
}
