.hide {
  /* IE and Edge */
  -ms-overflow-style: none;

  /* Firefox */
  scrollbar-width: none;
}

/* Safari and Chrome */
.hide::-webkit-scrollbar {
  display: none;
}
